<template>
    <header
        class="pendo-page-header"
        :class="{
            'pendo-page-header--editable': editable,
            'is-wrapped': isWrapped
        }"
        :style="{
            '--backgroundColor': backgroundColor,
            '--minHeight': headerSize
        }">
        <div
            ref="wrapper"
            class="pendo-page-header__wrapper">
            <!-- @slot Overrides all other slots and content in the page header -->
            <slot>
                <div
                    ref="content"
                    class="pendo-page-header__content">
                    <div
                        v-if="hasTop"
                        v-pendo-loading="{ loading, skeletonProps: { width: 400 } }"
                        class="pendo-page-header__top-wrapper">
                        <slot name="top">
                            <div v-if="loading">
                                &ensp;
                            </div>
                        </slot>
                    </div>
                    <div
                        v-pendo-loading="{ loading, skeletonProps: { width: 400, height: 44 } }"
                        class="pendo-page-header__title-wrapper"
                        :class="{
                            'pendo-page-header__title-wrapper--tags': hasTags
                        }">
                        <h1 v-if="loading">
                            &ensp;
                        </h1>
                        <slot
                            v-if="!loading"
                            name="title">
                            <pendo-editable-content
                                v-if="editable"
                                content-slot-class="pendo-page-header__title"
                                auto-width
                                :max-width="maxWidth"
                                exit-event="blur"
                                :value="title"
                                v-bind="$attrs"
                                v-on="$listeners">
                                <template #content>
                                    <h1 class="pendo-page-header__title">
                                        {{ title }}
                                    </h1>
                                </template>
                            </pendo-editable-content>
                            <h1
                                v-if="!editable"
                                class="pendo-page-header__title">
                                {{ title }}
                            </h1>
                        </slot>
                        <div
                            v-if="hasTags"
                            ref="tags"
                            class="pendo-page-header__tags">
                            <!-- @slot Content shown to the immediate right of the title -->
                            <slot name="tags" />
                        </div>
                    </div>
                    <div
                        v-if="hasBottom"
                        v-pendo-loading="{ loading, skeletonProps: { width: 400 } }"
                        class="pendo-page-header__bottom-wrapper">
                        <slot name="bottom">
                            <div v-if="loading">
                                &ensp;
                            </div>
                        </slot>
                    </div>
                </div>
                <div
                    v-if="hasActions"
                    ref="actions"
                    class="pendo-page-header__actions-wrapper">
                    <slot name="actions" />
                </div>
            </slot>
        </div>
    </header>
</template>

<script>
import PendoEditableContent from '@/components/editable-content/pendo-editable-content';
import PendoLoading from '@/directives/loading/pendo-loading';
import { hasSlot } from '@/utils/utils';

export default {
    name: 'PendoPageHeader',
    components: {
        PendoEditableContent
    },
    directives: {
        PendoLoading
    },
    inheritAttrs: false,
    props: {
        title: {
            type: String,
            default: undefined
        },
        editable: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        backgroundColor: {
            type: String,
            default: '#fff'
        },
        size: {
            type: String,
            default: 'medium',
            validator: (size) => ['medium', 'large'].includes(size)
        }
    },
    data () {
        return {
            maxWidth: '100%',
            isWrapped: false,
            actionObserver: null,
            wrapperObserver: null
        };
    },
    computed: {
        hasActions () {
            return hasSlot(this, 'actions');
        },
        hasTop () {
            return hasSlot(this, 'top');
        },
        hasBottom () {
            return hasSlot(this, 'bottom');
        },
        hasTags () {
            return hasSlot(this, 'tags');
        },
        headerSize () {
            return this.size === 'large' ? '220px' : '150px';
        }
    },
    mounted () {
        this.setMaxWidth();

        if (this.hasActions) {
            this.initActionObserver();
        }

        if (this.editable || this.hasActions) {
            this.initWrapperObserver();
        }
    },
    beforeDestroy () {
        if (this.actionObserver) {
            this.actionObserver.disconnect();
            this.actionObserver = null;
        }

        if (this.wrapperObserver) {
            this.wrapperObserver.disconnect();
            this.wrapperObserver = null;
        }
    },
    methods: {
        setMaxWidth () {
            let maxWidth = this.$refs.wrapper.clientWidth - 36;
            if (this.hasTags && this.$refs.tags) {
                maxWidth -= this.$refs.tags.clientWidth;
            }
            this.maxWidth = maxWidth;
        },
        initActionObserver () {
            this.actionObserver = new ResizeObserver((entries) => {
                const actionsSlot = entries[0].target;
                this.isWrapped = actionsSlot.offsetTop > this.$refs.content.offsetTop;
            });
            this.actionObserver.observe(this.$refs.actions);
        },
        initWrapperObserver () {
            this.wrapperObserver = new ResizeObserver(() => {
                if (this.hasActions && this.$refs.actions) {
                    this.isWrapped = this.$refs.actions.offsetTop > this.$refs.content.offsetTop;
                }
                if (this.editable) {
                    this.setMaxWidth();
                }
            });
            this.wrapperObserver.observe(this.$refs.wrapper);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-page-header) {
    min-height: var(--minHeight);
    padding: 16px 32px 0px; // 16px top to account for 8px margin bottom on wrapping actions slot
    display: grid;
    align-content: center;
    background-color: var(--backgroundColor);

    @include element(wrapper) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow: hidden;
    }

    @include element(content) {
        min-width: 0px;
        max-width: 100%;
        margin-bottom: 16px;
        padding-right: 16px;
        cursor: default;
        display: grid;
        grid-gap: 8px;
        flex: 1 1 auto;
    }

    h1,
    .pendo-page-header__title {
        font-weight: 600;
        font-size: 36px;
        font-style: inherit;
        line-height: 42px;
        margin: 0;
        margin-right: 22px;
        height: auto;
        @include font-display;
        @include ellipsis;
    }

    @include element(title-wrapper) {
        display: grid;

        @include modifier(tags) {
            grid-template-columns: minmax(0, max-content) min-content;
        }
    }

    @include element(actions-wrapper) {
        white-space: nowrap;
        min-width: 0;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        max-width: 100%;
        flex: 0 0 auto;
    }

    @include element((bottom-wrapper, top-wrapper)) {
        display: flex;
        flex-wrap: wrap;
    }

    @include element(tags) {
        display: flex;
    }

    @include modifier(editable) {
        h1,
        .pendo-page-header__title {
            border-bottom: 0;
            margin-right: 0px;
            border-bottom: 2px dashed transparent;
        }
    }

    .pendo-editable-content {
        grid-template-columns: fit-content(100%);

        .pendo-input__field {
            @include no-focus-ring;
        }
    }

    .pendo-editable-content__slot-content {
        overflow: hidden;
    }
}
</style>
