<template>
    <task-list-metrics-table
        v-if="taskListData.length"
        :agg-stream="aggStream"
        :table-user-settings="taskListTableUserSettings"
        :task-list-data="taskListData"
        :segments-map="segmentsMap"
        :csv-config="csvConfig"
        @tableSettingsUpdate="updateTaskListTableSettings"
        @onMetricClick="openDrawerAndFetchData" />
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import TaskListMetricsTable from '@/stateless-components/guides/task-list/TaskListMetricsTable.vue';
import { connectAggregationToState, withComponent } from '@pendo/agg-connect';
import taskListMetrics from '@/aggregations/task-list-metrics';
import { getTaskListItemClicks } from '@/aggregations/task-list-click-metrics';
import { PendoGuideElementsDrawerHeader, PendoGuideMetricsDrawerContent } from '@pendo/components';
import {
    getChildGuidesByStepId,
    getTaskLists,
    taskListTableUserSettingsName
} from '@/stateless-components/utils/task-list';
import { addHrefToVisitors, VISITOR_URL } from '@/utils/utils';
import { identifiedState } from '@/utils/aggregations';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: 'GuideTaskListMetrics',
    components: {
        TaskListMetricsTable
    },
    props: {
        guide: {
            type: Object,
            Required: true,
            default: null
        },
        activeTimeSeries: {
            type: Object,
            default: null
        },
        csvDownloadAggId: {
            type: String,
            default: () => 'task-list-metrics'
        }
    },
    data () {
        return {
            taskListData: [],
            aggStream: null,
            childGuidesMapByStepId: {}
        };
    },
    computed: {
        ...mapGetters({
            getGuideById: 'guides/getGuideById',
            getTableUserSettingValueByName: 'userSettings/getCrossUISettingByName',
            activeSegment: 'filters/activeSegment'
        }),
        ...mapState({
            activeId: (state) => state.guides.activeId,
            activeSegmentId: (state) => state.filters.activeSegmentId,
            guideMap: (state) => state.guides.map,
            segmentsMap: (state) => state.filters.segmentsMap,
            dateRange: (state) => state.filters.dateRange
        }),
        appIds () {
            return get(this, 'guide.appIds', '');
        },
        taskListTableUserSettings () {
            return this.getTableUserSettingValueByName(taskListTableUserSettingsName)?.value;
        },
        csvConfig () {
            const segment = get(this.activeSegment, 'name');
            const dateRange = get(this.dateRange, 'label');

            return {
                filename: `Guide Task List - ${dateRange} - ${segment}`
            };
        }
    },
    async created () {
        await this.getTaskListData();
        await this.fetchGuides();
        this.childGuidesMapByStepId = getChildGuidesByStepId(this.taskListData, this.guideMap);

        if (!isEmpty(this.childGuidesMapByStepId)) {
            this.createTaskListMetricsTableActivityAgg$();
        }
    },
    methods: {
        ...mapActions({
            getBuildingBlocks: 'guides/getBuildingBlocks',
            fetchGuides: 'guides/fetch',
            updateUserSettingByName: 'userSettings/updateCrossUINamespaceSetting'
        }),
        async getTaskListData () {
            await this.getBuildingBlocks({ guide: this.guide });

            this.taskListData = getTaskLists(this.guide);
        },
        createTaskListMetricsTableActivityAgg$ () {
            const sourcesOfState = {
                component: this
            };
            this.aggStream = connectAggregationToState(
                taskListMetrics,
                withComponent({
                    timeSeries: () => ({ ...this.activeTimeSeries, period: 'dayRange' }),
                    segmentId: 'activeSegmentId',
                    parentGuideId: 'activeId',
                    childGuidesMapByStepId: 'childGuidesMapByStepId'
                })
            )(sourcesOfState);
        },
        updateTaskListTableSettings (settingsName, newUserSettings) {
            this.updateUserSettingByName({
                name: settingsName,
                value: newUserSettings
            });
        },
        async fetchVisitorsForTaskListItemClicks (row) {
            const timeSeries = {
                ...this.activeTimeSeries,
                period: 'dayRange'
            };

            const response = await getTaskListItemClicks({
                appIds: this.appIds,
                guideId: this.guide.id,
                timeSeries,
                segmentId: this.activeSegmentId,
                childGuideId: row.id,
                stepId: row.parentId,
                includeAnonymous: identifiedState(this.activeSegmentId)
            });

            const visitors = response[0];

            return addHrefToVisitors(visitors);
        },
        async openDrawerAndFetchData (row) {
            this.$emit('updateSelectedRow', row);

            this.setSidePanelState({
                row,
                loading: true
            });

            const visitors = await this.fetchVisitorsForTaskListItemClicks(row);
            this.$emit('updateGuideElementsClicks', visitors);

            this.setSidePanelState({
                row,
                data: visitors,
                loading: false
            });
        },
        setSidePanelState ({ row, data, loading, csvModalStatus = 'loading', csvUrl = '' }) {
            const state = {
                visible: true,
                header: {
                    component: PendoGuideElementsDrawerHeader,
                    props: {
                        uiElement: {
                            uiElementText: row.name,
                            uiElementType: 'Guide'
                        },
                        class: 'task-list-metric-header'
                    }
                },
                body: {
                    component: PendoGuideMetricsDrawerContent,
                    props: {
                        csvDownloadAggId: this.csvDownloadAggId,
                        data,
                        loading,
                        csvModalStatus,
                        csvUrl,
                        visitorUrl: VISITOR_URL,
                        showAccountsTab: false,
                        showCreateSegment: false,
                        showClicksColumn: true,
                        theme: 'app'
                    }
                }
            };
            this.$emit('openSidePanel', { ...state, visible: true });
        }
    }
};
</script>

<style lang="scss">
.task-list-metric-header {
    .pendo-guide-elements-drawer__subtitle {
        .pendo-divider {
            display: none;
        }
    }
}
</style>
