import { render, staticRenderFns } from "./GuideDetailsSchedule.vue?vue&type=template&id=388c5144&scoped=true&"
import script from "./GuideDetailsSchedule.vue?vue&type=script&lang=js&"
export * from "./GuideDetailsSchedule.vue?vue&type=script&lang=js&"
import style0 from "./GuideDetailsSchedule.vue?vue&type=style&index=0&id=388c5144&prod&scoped=true&lang=scss&"
import style1 from "./GuideDetailsSchedule.vue?vue&type=style&index=1&id=388c5144&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388c5144",
  null
  
)

export default component.exports