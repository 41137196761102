import { render, staticRenderFns } from "./WorkflowTotalsChart.vue?vue&type=template&id=b9868fec&scoped=true&"
import script from "./WorkflowTotalsChart.vue?vue&type=script&lang=js&"
export * from "./WorkflowTotalsChart.vue?vue&type=script&lang=js&"
import style0 from "./WorkflowTotalsChart.vue?vue&type=style&index=0&id=b9868fec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9868fec",
  null
  
)

export default component.exports